/**
 * Constante que contiene la zona horaria de Chile
 */
export const zonaHoraria = 'America/Santiago';

/**
 * Constante que contiene el tipo de idioma de la hora en español de chile
 */
export const LOCALE_ES_CL = 'es-CL';

/**
 * Constante que contiene el tipo de idioma de la hora en ingles de Estados unidos
 */
export const LOCALE_EN_US = 'en-US';

/**
 * Constante que coneitne el Tipo de formato para la hora
 */
export const tipoFormateo = 'M-d-yyyy, HH:mm:ss';

/**
 * Constante que contiene el valor de cuantos milisegundos existen por cada minuto
 */
export const milisegundosPorMinuto = 60000;

/**
 * Constante que contiene el numero de caracteres maximos en los selects
 */
export const cantidadCaracteresMaximosSelect = 23;

/**
 * Constante de tipo de suscripción de notificacion de TER
 */
export const tipoDeSuscripcion = 'email';

/**
 * Constante que contiene el tiempo de recarga de los captchas
 */
export const tiempoRecarga = 6000;

/**
 * Constante que contiene el tiempo de recarga de los captcha v2
 */
export const tiempoRecargaV2 = 120000;

/**
 * Constante que contiene el tiempo de recarga de los estilos d captcha v3
 */
export const tiempoRecargaEstilosCaptchaV3 = 100;

/**
 * Constante para el umbral de ancho de ventana para diferenciar entre móvil y escritorio
 */
export const UMBRAL_ANCHO_VENTANA = 768;

/**
 * Constante que contiene el minimo de caracteres posibles para un input de numero de cliente
 */
export const MINIMO_CARACTERES_NUMERO_DE_CLIENTE = 0;

/**
 * Constante que contiene el maximo de caracteres posibles para un input de numero de cliente
 */
export const MAXIMO_CARACTERES_NUMERO_DE_CLIENTE = 8;

/**
 * Constante que contiene el regex en el cual limita a que sean un total de ocho numeros
 */
export const REGEX_MAXIMO_OCHO_NUMEROS = /^\d{8}$/;
