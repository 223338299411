import { createStyles, makeStyles } from '@material-ui/core';
import { theme } from '@saesa/ui-kit-front';

const MapsStyles = makeStyles(() =>
  createStyles({
    buscadorCnt: {
      width: '350px',
      padding: '0.875rem',
      background: 'white',
      boxShadow: '0px 0.125rem 1.25rem hsla(var(--valor-primario-azul-oscuro), 0.14)',
      borderRadius: '0.625rem',
      position: 'relative',
      top: '2rem',
      left: '3.125rem',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      [theme.breakpoints.down('sm')]: {
        top: '1.875rem',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'block',
      },
    },
    inputCnt: {
      position: 'relative',
      width: '100%',
    },
    formCnt: {
      position: 'relative',
      width: '100%',
      height: 'auto',
    },
    inputNroServicio: {
      width: '100%',
      color: 'var(--gris-l43)',
      [theme.breakpoints.down('sm')]: {
        width: '310px',
        marginBottom: '0.625rem',
      },
    },
    inputDireccion: {
      color: 'var(--gris-l43)',
      [theme.breakpoints.down('sm')]: {
        width: '310px',
        marginBottom: '0.625rem',
      },
    },
    lupaIcon: {
      '--icon-primary-dark': 'var(--gris-l43)',
    },
    btnIconDireccion: {
      position: 'absolute',
      right: '1.275rem',
      display: 'flex',
      alignItems: 'center',
      background: 'transparent',
      border: 'none',
      cursor: 'pointer',
    },
    flechaIcon: {
      '--icon-primary-dark': 'var(--primario-morado)',
      transition: '.5s ease all',
    },
    flechaIconDisabled: {
      opacity: 0.5,
    },
    inputHasError: {},
    esconderElemento: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    textoCambiar: {
      fontSize: '.875rem',
      color: 'var(--primario-morado)',
      textAlign: 'center',
    },
    modal: {
      zIndex: 1,
    },
    principal: {
      display: 'flex',
      position: 'relative',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    circulo: {
      display: 'flex',
      position: 'relative',
      justifyContent: 'start',
      alignItems: 'center',
      width: '2.75rem',
      height: '2.75rem',
      borderRadius: '50%',
      margin: '2.5rem 9.375rem 0 0',
      '&:first-child': {
        marginLeft: '0',
        marginRight: '9.375rem',
        '&::before': {
          width: '2.5rem',
          transform: 'translateY(-2.625rem) rotate(90deg)',
        },
      },
      '&::before': {
        display: 'block',
        position: 'absolute',
        content: '""',
        height: '.25rem',
        width: '2.5rem',
        transform: 'translateY(-2.625rem) rotate(90deg)',
      },
      '&:last-child': {
        margin: '2.5rem 9.375rem 2.5rem 0',
      },
    },

    textos: {
      position: 'absolute',
      top: '-40px',
      width: 'max-content',
      transform: 'translate(65px, 45px)',
      textAlign: 'start',
    },

    iconoCnt: {
      display: 'flex',
      padding: '1rem 0',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    icono: {
      transform: 'translateX(10px)',
    },
    disclaimerQuinceMinutos: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '8px 20px',
      gap: '10px',
      background: 'var(--gris-l96)',
      borderRadius: '0px 0px 15px 15px',
      margin: '-0.875rem',
      marginTop: '0px',
      width: 'calc(100% + 0.875rem + 0.875rem)',
    },
    textoDisclaimerQuinceMinutos: {
      display: 'flex',
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      textAlign: 'center',
      color: 'var(--gris-l43)',
      justifyContent: 'center',
    },
    contenedorBuscadorDireccion: {
      width: '100%',
    },
  })
);

export default MapsStyles;
