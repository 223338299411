import { useEffect, useState } from 'react';
import { Button, IconButton, Typography, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { ARROW_UP, ARROW_DOWN } from 'assets/images';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actualizarAcordeonNotificacionTerAbierto, actualizarEstadoCaptchaV3, actualizarEstadoClienteSuscrito, RootState } from 'store';
import * as Yup from 'yup';
import {
  MAXIMO_CARACTERES_NUMERO_DE_CLIENTE,
  MINIMO_CARACTERES_NUMERO_DE_CLIENTE,
  REGEX_MAXIMO_OCHO_NUMEROS,
  tipoDeSuscripcion,
} from 'utils/constants';
import { useSnackbar } from 'notistack';
import CaptchaV2 from 'components/Captchas/CaptchaV2';
import { QueryParams } from 'config';
import NotificacionService from './NotificacionService';
import SuscritoNotificacionTer from './SuscritoNotificacionTer';
import './Selector.css';
import useStyles from './AcordeonNotificacionTerStyles';

const AcordeonNotificacionTer = ({ numeroCaso }: { numeroCaso: string }) => {
  /**
   * Hook de traducción de textos
   */
  const { t } = useTranslation();

  /**
   * Hook de estilos
   */
  const styles = useStyles();

  /**
   * Disparador para el selector
   */
  const dispatch = useDispatch();

  /**
   * Notificaciones en forma de pildora
   */
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Obtiene el valor del estado del captcha v3
   */
  const estadoCaptchaV3 = useSelector((state: RootState) => state.desconexion.captchaV3Estado);

  /**
   * Obtiene el valor del token de captcha v3
   */
  const tokenCaptchaV3 = useSelector((state: RootState) => state.desconexion.tokenCaptchaV3);

  /**
   * Obtiene el estado de la suscripción del cliente
   */
  const estadoDeClienteSuscrito = useSelector((state: RootState) => state.desconexion.clienteSuscritoTer);

  /**
   * Obtiene el estado de abierto del acordeon
   */
  const estadoAcordeonAbierto = useSelector((state: RootState) => state.desconexion.acordeonNotificacionTerAbierto);

  /**
   * Variable que maneja query params
   */
  const query = QueryParams();

  /**
   * valor de cliente obtenido por query params
   */
  const queryNroCliente = query.get('cliente') || '';

  /**
   * Valores iniciales de formulario
   */
  const initialValues = {
    email: '',
    numeroCliente: queryNroCliente || '',
  };

  /**
   * Valor de formik
   */
  /* eslint-disable prefer-const */
  let formik: any;

  /**
   *  UseState que contiene token generado por el captcha V2
   */
  const [tokenCaptchaV2, setTokenCaptchaV2] = useState<any>('');

  /**
   *  UseState que contiene el estado de reiniciar el captcha
   */
  const [reiniciarCaptchaState, setReiniciarCaptchaState] = useState(false);

  /**
   *  UseState que contiene el validador del boton enviar
   */
  const [validarBoton, setValidarBoton] = useState(true);

  /**
   * Funcion que controla el abrir y cerrar acordeon
   */
  const handleAcordeon = () => {
    dispatch(actualizarAcordeonNotificacionTerAbierto(!estadoAcordeonAbierto));

    if (formik.values.email === '') {
      dispatch(actualizarEstadoClienteSuscrito(false));
    }
  };

  /**
   * Controlador de disparador en el boton enviar correo
   */
  const onSubmit = () => {
    NotificacionService.solicitarNotificacionTer(
      estadoCaptchaV3,
      estadoCaptchaV3 ? tokenCaptchaV3 : tokenCaptchaV2,
      formik.values.email,
      formik.values.numeroCliente,
      numeroCaso,
      tipoDeSuscripcion,
      t,
      enqueueSnackbar,
      dispatch,
      actualizarEstadoClienteSuscrito,
      actualizarEstadoCaptchaV3,
      setReiniciarCaptchaState
    );
  };

  /**
   * Validaciones de errores del formulario
   */
  formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: Yup.object({
      email: Yup.string().required(t('errors:enviarNotificacionTer.emailRequerido')).email(t('errors:enviarNotificacionTer.email')),
      numeroCliente: Yup.string()
        .required(t('errors:enviarNotificacionTer.numeroServicioRequerido'))
        .length(MAXIMO_CARACTERES_NUMERO_DE_CLIENTE, t('errors:enviarNotificacionTer.numeroServicio'))
        .matches(REGEX_MAXIMO_OCHO_NUMEROS, t('errors:enviarNotificacionTer.numeroServicio')),
    }),
  });

  /**
   * Función que controla el estado del input email
   * @param event de clic
   */
  const onHandleBlur = (event: any) => {
    if (event.nativeEvent.target.value.length > 0) {
      const email = event.nativeEvent.target.value;
      const newEvent = { ...event };
      formik.values.emails = email;
      formik.handleBlur(newEvent);
    } else {
      formik.handleBlur(event);
    }
  };

  /**
   * Función que controla el estado del input de número de cliente
   * Asegura que solo se ingresen números
   * @param event de clic
   */
  const onHandleBlurNumeroCliente = (event: any) => {
    if (
      event.nativeEvent.target.value.length > MINIMO_CARACTERES_NUMERO_DE_CLIENTE &&
      event.nativeEvent.target.value.length <= MAXIMO_CARACTERES_NUMERO_DE_CLIENTE
    ) {
      // Limpiar el valor para asegurar que solo se almacenen números
      const numeroCliente = event.nativeEvent.target.value.replace(/\D/g, ''); // Filtra todo lo que no sea número
      const newEvent = { ...event };
      formik.values.numeroCliente = numeroCliente;
      formik.handleBlur(newEvent);
    } else {
      formik.handleBlur(event);
    }
  };

  /**
   * Función para manejar el cambio de valor en el campo numeroCliente
   * Asegura que solo se ingrese números y no más de 8 caracteres
   */
  const onHandleChangeNumeroCliente = (event: any) => {
    // Filtrar todo lo que no sea un número
    const numericValue = event.target.value.replace(/\D/g, '');

    // Limitar el valor a 8 caracteres
    const limitedValue = numericValue.substring(MINIMO_CARACTERES_NUMERO_DE_CLIENTE, MAXIMO_CARACTERES_NUMERO_DE_CLIENTE);

    formik.setFieldValue('numeroCliente', limitedValue);
  };

  /**
   * UseEffect inicializador de formik
   */
  useEffect(() => {
    if (!estadoAcordeonAbierto) {
      // Restablecer los campos tocados del formulario
      formik.setTouched({
        email: false,
        numeroCliente: false,
      });

      formik.resetForm({
        values: {
          email: '',
          numeroCliente: '',
        },
      });

      if (queryNroCliente !== '') {
        const numeroClienteFiltrado = queryNroCliente.replace(/\D/g, '');
        formik.setFieldValue('numeroCliente', numeroClienteFiltrado);
      }
    }

    formik.values.email = '';
  }, [estadoAcordeonAbierto, queryNroCliente]);

  /**
   * UseEffect que valida el estado del boton de enviar
   */
  useEffect(() => {
    setValidarBoton(
      !formik.isValid ||
        (tokenCaptchaV2 === '' && !tokenCaptchaV3) ||
        (!estadoCaptchaV3 && tokenCaptchaV2 === '') ||
        formik.values.email === ''
    );
  }, [formik, tokenCaptchaV2, tokenCaptchaV3, formik.values.email]);

  return (
    <>
      {!estadoDeClienteSuscrito && (
        <div className={styles.contenedorAcordeon}>
          <div className={`${styles.contenedorInterno} ${estadoAcordeonAbierto && styles.borderAcordeonAbierto}`}>
            <Typography className={styles.titulo}>{t('desconexion:paginas.mapaCortes.notificacionTer.titulo')}</Typography>
            <IconButton className={styles.botonAcordeon} aria-label="cerrar" onClick={handleAcordeon} data-testid="acordion-boton">
              {estadoAcordeonAbierto ? <img src={ARROW_UP} alt="cerrar" /> : <img src={ARROW_DOWN} alt="abrir" />}
            </IconButton>
          </div>
          {estadoAcordeonAbierto && (
            <div className={styles.content}>
              <div className={styles.internoContenedor}>
                <Typography className={styles.subtitulo}>{t('desconexion:paginas.mapaCortes.notificacionTer.subtitulo')}</Typography>

                <div className={styles.contenedorInput}>
                  <TextField
                    id="outlined-basic"
                    className={styles.inputNumero}
                    name="email"
                    value={formik.values.email}
                    label={t('desconexion:paginas.mapaCortes.notificacionTer.input')}
                    variant="outlined"
                    type="text"
                    onChange={formik.handleChange}
                    inputProps={{ 'data-testid': 'email' }}
                    onBlur={onHandleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      classes: {
                        root: styles.inputRoot,
                        input: styles.inputInput,
                      },
                    }}
                  />

                  <TextField
                    id="outlined-basic"
                    className={styles.inputNumero}
                    name="numeroCliente"
                    value={formik.values.numeroCliente}
                    label={t('desconexion:paginas.mapaCortes.notificacionTer.inputNumeroCliente')}
                    variant="outlined"
                    type="text"
                    onChange={onHandleChangeNumeroCliente}
                    inputProps={{ 'data-testid': 'numeroCliente' }}
                    onBlur={onHandleBlurNumeroCliente}
                    error={formik.touched.numeroCliente && Boolean(formik.errors.numeroCliente)}
                    helperText={formik.touched.numeroCliente && formik.errors.numeroCliente}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      classes: {
                        root: styles.inputRoot,
                        input: styles.inputInput,
                      },
                    }}
                  />
                </div>

                <div className={`${styles.contenedorCaptchaV2} ${estadoCaptchaV3 && styles.ocultarCaptcha}`}>
                  <div className={styles.recaptchaContainer}>
                    <CaptchaV2
                      setTokenCaptchaV2={setTokenCaptchaV2}
                      reiniciarCaptchaState={reiniciarCaptchaState}
                      setReiniciarCaptchaState={setReiniciarCaptchaState}
                    />
                  </div>
                </div>
              </div>

              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={onSubmit}
                data-testid="enviar-email"
                disabled={validarBoton}
              >
                {t('desconexion:paginas.mapaCortes.notificacionTer.boton')}
              </Button>
            </div>
          )}
        </div>
      )}
      {estadoDeClienteSuscrito && <SuscritoNotificacionTer correo={formik.values.email} />}
    </>
  );
};

export default AcordeonNotificacionTer;
